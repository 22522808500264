<template>
  <div class="border-primary rounded fill-height d-flex align-center py-5 py-sm-3">
    <confirm-dialog ref="archiveAcceptDialog" @confirm="removeAd('delete')">
      <template #title>
        {{ $t('main.ad_form.archive_ad_title', { name: ad.name }) }}
      </template>
      <template #body>
        {{ $t('main.ad_form.archive_ad', { name: ad.name }) }}
      </template>
    </confirm-dialog>
    <v-layout class="justify-center">
      <div
        v-for="(actionItem, index) in actions"
        :key="index"
        :class="[
          { 'pl-8 pl-sm-15 pl-lg-8': index !== 0 }
        ]"
      >
        <table-action
          :settings-action="actionItem"
          icon
          @edit="editAd()"
          @statistics="openStatistics()"
          @duplicable="openDuplicateForm()"
          @archive="$refs.archiveAcceptDialog.ask()"
        />
      </div>
    </v-layout>
  </div>
</template>

<script>
  import TableAction from '@clickadilla/components/ui/TableAction.vue'
  import routeNames from '@/types/route-names.js'
  import adsRepository from '@/services/repositories/ads-repository.js'
  import Ads from '@/services/classes/Ads.js'
  import ConfirmDialog from '@/components/ConfirmDialog.vue'

  export default {
    name: 'NewAdsActions',
    components: {
      TableAction,
      ConfirmDialog
    },
    props: {
      ad: {
        type: Ads,
        default: () => ({})
      }
    },
    data() {
      return {
        isArchiveDialogIsShown: false,
        deleteIsLoading: false
      }
    },
    computed: {
      actions() {
        return [
          {
            color: 'primary',
            method: 'edit',
            icon: '$pencil',
            text: this.$t('main.ad_form.action_edit', { name: this.ad.name }),
            disabled: this.ad.actions.editable,
            isLoading: this.deleteIsLoading
          },
          {
            color: 'info',
            method: 'statistics',
            icon: '$chart-line',
            text: this.$t('main.ad_form.action_statistics', { name: this.ad.name }),
            disabled: this.ad.actions.editable,
            isLoading: this.deleteIsLoading
          },
          {
            color: 'black',
            method: 'duplicable',
            icon: '$copy',
            text: this.$t('main.ad_form.duplicate', { name: this.ad.name }),
            disabled: this.ad.actions.duplicable
          },
          {
            color: 'error',
            method: 'archive',
            icon: '$trash',
            text: this.$t('main.ad_form.action_archive'),
            disabled: this.ad.actions.deletable,
            isLoading: this.deleteIsLoading
          }
        ]
      }
    },
    methods: {
      async openStatistics() {
        const campaignsIds = JSON.stringify(this.ad.campaigns.map(({ id }) => id))
        const adFormatIds = JSON.stringify(this.adFormatIds)
        await this.$router.push({
          name: routeNames.CAMPAIGN_STATISTICS,
          query: {
            campaigns: campaignsIds,
            groupBy: 'creative',
            adFormatIds
          }
        })
      },
      async editAd() {
        await this.$router.push({ name: routeNames.EDIT_AD, params: { id: this.ad.id } })
      },
      async openDuplicateForm() {
        await this.$router.push({ name: routeNames.DUPLICATE_AD, params: { id: this.ad.id } })
      },
      async removeAd() {
        this.deleteIsLoading = true
        try {
          await adsRepository.delete(this.ad.id)
          this.$showSuccessNotification(this.$t('main.ad_form.archived_ad', { name: this.ad.name }))
        } catch (error) {
          this.$showErrorNotification(this.$t('main.ad_form.error_archiving', { name: this.ad.name }))
        }
        this.deleteIsLoading = false
        this.$router.push({ name: routeNames.ADS })
      }
    }
  }
</script>
