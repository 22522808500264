<template>
  <v-card elevation="0" class="border-secondary">
    <v-card-title class="secondary">
      {{ $t('ads.creatives') }}
    </v-card-title>

    <v-skeleton-loader :loading="adIsLoading" type="list-item-avatar, list-item-avatar">
      <v-data-table
        :headers="headers"
        :items="ad.model.creatives"
        hide-default-footer
        :options.sync="options"
        @page-count="getPageCount($event)"
        @update:sort-by="changeCurrentPage()"
      >
        <template #item.url="{ item }">
          <adaptive-string-table>
            <a :href="item.url" target="_blank">
              {{ item.url }}
            </a>
          </adaptive-string-table>
        </template>

        <template #item.vastTagUri="{ item }">
          <adaptive-string-table>
            <a :href="item.vastTagUri" target="_blank">
              {{ item.vastTagUri }}
            </a>
          </adaptive-string-table>
        </template>

        <template #item.rotation="{ item }">
          <winner-chip v-if="checkIsWinner(item)" />
        </template>

        <template #item.isActive="{ item }">
          <v-checkbox
            color="primary"
            :label="item.isActive ? $t('ads.active') : $t('ads.not_active')"
            :disabled="activeCreativeIsLoading"
            :input-value="item.isActive"
            @change="
              changeCreativeIsActive({
                creativeId: item.id,
                isActive: item.isActive
              })
            "
          />
        </template>

        <template #item.showPreview="{ item }">
          <v-btn
            class="subtitle-2 px-8 text-capitalize"
            color="primary"
            small
            @click="showPreviewCreative(item)"
          >
            {{ $t('ads.show_preview') }}
          </v-btn>
        </template>
      </v-data-table>
      <data-table-footer
        :items-per-page="options.itemsPerPage"
        :page-count="pageCount"
        :current-page="options.page"
        @change-items-per-page="changeItemsPerPage($event)"
        @change-current-page="changeCurrentPage($event)"
      />
    </v-skeleton-loader>

    <instream-preview-dialog
      v-if="previewDialogIsShown"
      :preview-is-fullscreen="previewDialogIsShown"
      :creative="creativeForPreview"
      @close-dialog="previewDialogIsShown = false"
    />
  </v-card>
</template>

<script>
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import { adTypes } from '@clickadilla/components/constants/ad-types.js'
  import WinnerChip from '@/components/WinnerChip.vue'
  import Ads from '@/services/classes/Ads.js'
  import InstreamPreviewDialog from '@/components/ads/InstreamForm/InstreamPreviewDialog.vue'

  export default {
    name: 'InStreamCreativesCard',
    components: {
      WinnerChip,
      DataTableFooter,
      InstreamPreviewDialog
    },
    props: {
      ad: {
        type: Ads,
        required: true
      },
      adIsLoading: {
        type: Boolean,
        required: true
      },
      activeCreativeIsLoading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        options: {
          itemsPerPage: 15,
          page: 1
        },
        pageCount: 0,
        previewDialogIsShown: false,
        creativeForPreview: null
      }
    },
    computed: {
      headers() {
        const activeHeaders = [
          {
            value: 'id',
            text: 'ID',
            width: '80px',
            isShown: true
          },
          {
            value: 'url',
            text: 'URL',
            isShown: [adTypes.VIDEO, adTypes.MOTION_BANNER].includes(this.ad.model.type)
          },
          {
            value: 'vastTagUri',
            text: 'VastTagUri',
            width: '200px',
            isShown: [adTypes.WRAPPER_WITH_VPAID, adTypes.WRAPPER_WITH_VIDEO].includes(
              this.ad.model.type
            )
          },
          {
            value: 'showPreview',
            text: this.$t('ads.show_preview'),
            width: '160px'
          },
          {
            value: 'motionBannerParams.titleText',
            text: this.$t('ads.motion_banner.title_text'),
            width: '200px',
            isShown: this.ad.model.type === adTypes.MOTION_BANNER
          },
          {
            value: 'motionBannerParams.buttonText',
            text: this.$t('ads.motion_banner.button_text'),
            width: '200px',
            isShown: this.ad.model.type === adTypes.MOTION_BANNER
          },
          {
            value: 'motionBannerParams.additionalText',
            text: this.$t('ads.motion_banner.additional_text'),
            width: '200px',
            isShown: this.ad.model.type === adTypes.MOTION_BANNER
          },
          {
            value: 'motionBannerParams.descriptionText',
            text: this.$t('ads.motion_banner.description_text'),
            width: '200px',
            isShown: this.ad.model.type === adTypes.MOTION_BANNER
          },
          {
            value: 'isActive',
            text: this.$t('ads.creative_status'),
            width: '200px'
          },
          {
            value: 'priority',
            text: this.$t('ads.priority'),
            width: '100px'
          }
        ]
        return activeHeaders.filter(({ isShown }) => isShown !== false)
      }
    },
    methods: {
      changeCreativeIsActive({ creativeId, isActive }) {
        this.$emit('active-creative-handler', { creativeId, isActive })
      },
      async showPreviewCreative(creative) {
        this.creativeForPreview = {
          ...creative,
          type: this.ad.model.type,
          skipTime: this.ad.model.skipTime
        }
        this.previewDialogIsShown = true
      },
      checkIsWinner(creative) {
        if (!this.ad.model.rotation) {
          return false
        }
        return this.ad.model.rotation.winnerId === creative.id
      },
      changeItemsPerPage(itemsPerPage) {
        this.options.page = 1
        this.options.itemsPerPage = itemsPerPage
      },
      getPageCount(pageCount) {
        this.options.page = 1
        this.pageCount = pageCount
      },
      changeCurrentPage(page = 1) {
        this.options.page = page
      }
    }
  }
</script>
